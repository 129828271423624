<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex justify-center align-center h-full pa-16">
              <v-img
                contain
                max-width="60%"
                :src="require('@/assets/images/illustrations/reset-password.png')"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Reset Your Password 🔑
                  </p>
                  <p class="mb-2">
                    Your new password must be different from previously used passwords
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="resetForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="passwordConfirmation"
                      outlined
                      :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                      label="Confirm Password"
                      :error-messages="errorMessages.password_confirmation"
                      placeholder="Confirm Password"
                      :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                      @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      type="submit"
                      color="primary"
                      :loading="isLoading"
                      :disabled="isLoading"
                    >
                      Set new password
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center mt-2">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline, mdiChevronLeft } from '@mdi/js'
import { ref, inject } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { axios } from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Initializers
    const resetForm = ref(null)
    const snackbarService = inject('snackbarService')
    const { router, route } = useRouter()

    // Properties
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const password = ref(null)
    const passwordConfirmation = ref(null)
    const errorMessages = ref([])
    const isLoading = ref(false)

    // Methods
    const handleFormSubmit = () => {
      isLoading.value = true
      const isFormValid = resetForm.value.validate()

      if (!isFormValid) return

      axios
        .put('/auth/password', {
          password: password.value,
          password_confirmation: passwordConfirmation.value,
          reset_password_token: route.value.query.reset_password_token,
        })
        .then(response => {
          isLoading.value = false
          snackbarService.success(response.data.message)
          router.push('/login')
        })
        .catch(error => {
          isLoading.value = false
          errorMessages.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong. Please refresh!')
        })
    }

    return {
      // Initializers
      resetForm,

      // Properties
      isPasswordVisible,
      isPasswordConfirmationVisible,
      password,
      passwordConfirmation,
      errorMessages,
      isLoading,

      // Others
      validators: {
        required,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Methods
      handleFormSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
